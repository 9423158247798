import React, { Component, lazy, Suspense } from 'react';
import { Route, HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Api from './utils/Api';
const browserHistory = createBrowserHistory();
const StartPage = lazy(() => import('./start'));
const LoginPage = lazy(() => import('./pages/login'));
require("./assets/css/my_base.css");
const getApi = Api.getInstance;

class App extends Component {
    render() {
        return (
            <Suspense fallback={<div></div>}>
                <HashRouter history={browserHistory}>
                    <React.Fragment>
                    <Route path='/main' component={StartPage} />
                    <Route path='/login' component={LoginPage} />
                    <Route path='/' exact component={LoginPage} />
                    </React.Fragment>
                </HashRouter>
            </Suspense>
        );
    }
    componentWillUnmount(){
        getApi().doLogOut().then((res)=>{
            console.log("logout");
        })
    }
}

export default App;

