import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import {persistor} from './store';
import {PersistGate} from 'redux-persist/lib/integration/react';
/* 时间控件-国际化 */
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-CN');

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider locale={zh_CN}>
                <App />
            </ConfigProvider>
        </PersistGate>
    </Provider>,
    document.querySelector('#root'),
);
